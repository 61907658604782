<template>
  <div class="page">
    <div class="content-box section">
      <div class="btn">
        <icon-button slot="extra" icon="return" @click.native="goBack"/>
      </div>
      <!-- <div>
        <el-row class="title">
          <el-col :span="12">
            <div
              class="grid-content"
              :class="{ titleBackground: nowBackground ? true : false }"
            >
              第一步：企业资质认证
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="grid-content"
              :class="{ titleBackground: !nowBackground ? true : false }"
            >
              第二步：提现账户验证
            </div>
          </el-col>
        </el-row>
      </div> -->

      <!-- 第一步：企业资质认证 -->
      <div v-if="nowBackground === true">
        <div style="margin-top: 32px">
          <el-form
              ref="model"
              :inline="true"
              :model="model"
              :rules="rules"
              size="medium"
              style="width: 80%; margin: 0 auto"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                    label="企业名称"
                    label-width="180px"
                    prop="enterpriseName"
                    required
                >
                  <el-input
                      v-model="model.enterpriseName"
                      placeholder="请输入企业名称"
                      prefix-icon=""
                      style="width: 350px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                    label="企业证件类型"
                    label-width="180px"
                    prop="enterprisePaperworkType"
                    required
                >
                  <el-select
                      v-model="model.enterprisePaperworkType"
                      placeholder="请选择企业证件类型"
                      style="width: 350px"
                  >
                    <el-option label="三证合一" value="1"></el-option>
                    <el-option label="普通" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item
                    label="企业证件号"
                    label-width="180px"
                    prop="unifiedSocialCreditCode"
                    required
                >
                  <el-input
                      v-model="model.unifiedSocialCreditCode"
                      placeholder="请输入营业执照上的统一社会信用代码或营业执照编号"
                      prefix-icon=""
                      style="width: 350px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                    label="法人姓名"
                    label-width="180px"
                    prop="legalPersonName"
                    required
                >
                  <el-input
                      v-model="model.legalPersonName"
                      placeholder="请输入法人姓名"
                      prefix-icon=""
                      style="width: 350px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item
                    label="身份证号码"
                    label-width="180px"
                    prop="idCard"
                    required
                >
                  <el-input
                      v-model="model.idCard"
                      placeholder="请输入身份证号码"
                      prefix-icon=""
                      style="width: 350px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                    label="法人手机号"
                    label-width="180px"
                    prop="legalPersonTel"
                    required
                >
                  <el-input
                      v-model="model.legalPersonTel"
                      placeholder="请输入法人手机号"
                      prefix-icon=""
                      style="width: 350px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item
                    label="开户行代码"
                    label-width="180px"
                    prop="bankCode"
                    required
                >
                  <el-input
                      v-model="model.bankCode"
                      placeholder="请输入开户行代码（支付行号）"
                      prefix-icon=""
                      style="width: 350px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                    label="开户行支行名称"
                    label-width="180px"
                    prop="accountOpeningBranch"
                    required
                >
                  <el-input
                      v-model="model.accountOpeningBranch"
                      placeholder="请输入开户行支行名称"
                      prefix-icon=""
                      style="width: 350px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item
                    label="银行账户名称"
                    label-width="180px"
                    prop="bankAccountName"
                    required
                >
                  <el-input
                      v-model="model.bankAccountName"
                      placeholder="请输入银行账户名称"
                      prefix-icon=""
                      style="width: 350px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                    label="银行账户号"
                    label-width="180px"
                    prop="bankAccountNumber"
                    required
                >
                  <el-input
                      v-model="model.bankAccountNumber"
                      placeholder="请输入银行账户号"
                      prefix-icon=""
                      style="width: 350px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item
                    label="联系人姓名"
                    label-width="180px"
                    prop="contactName"
                    required
                >
                  <el-input
                      v-model="model.contactName"
                      placeholder="请输入联系人姓名"
                      prefix-icon=""
                      style="width: 350px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                    label="联系人电话"
                    label-width="180px"
                    prop="contactTel"
                    required
                >
                  <el-input
                      v-model="model.contactTel"
                      placeholder="请输入联系人电话"
                      prefix-icon=""
                      style="width: 350px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item
                    label="联系人身份证号码"
                    label-width="180px"
                    prop="contactIdCard"
                    required
                >
                  <el-input
                      v-model="model.contactIdCard"
                      placeholder="联系人身份证号码"
                      prefix-icon=""
                      style="width: 350px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div class="footer-btn">
          <!-- 原下一步,不支持第二步，暂时改为保存 -->
          <el-button type="primary" @click="next">保存</el-button>
        </div>
      </div>
      <!-- 第二步：提现账户验证 -->
      <div v-else>
        <div>
          <el-form
              ref="withDrawModel"
              :inline="true"
              :model="withDrawModel"
              :rules="rulesWithDraw"
              size="medium"
              style="width: 56%; margin: 30px auto"
          >
            <el-row>
              <el-form-item
                  label="银行账户号"
                  label-width="180px"
                  prop="bankAccountNumber"
                  required
              >
                <el-input
                    v-model="withDrawModel.bankAccountNumber"
                    disabled
                    placeholder="请输入银行账户号"
                    prefix-icon=""
                    style="width: 900px"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                  label="联系人手机号"
                  label-width="180px"
                  prop="contactsMobile"
                  required
              >
                <el-input
                    v-model="withDrawModel.contactsMobile"
                    disabled
                    placeholder="请输入联系人手机号"
                    prefix-icon=""
                    style="width: 900px"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                  label="提现账户验证"
                  label-width="180px"
                  prop="contactsMobile"
              >
                <el-button v-if="applyBtn === true" type="primary" @click="apply">申请小额打款和短信指令验证</el-button>

                <span v-else>
              <el-input
                  v-model="withDrawModel.amount"
                  placeholder="请输入银行账户到账金额"
                  prefix-icon=""
                  style="width: 444px"
              ></el-input>

              <el-input
                  v-model="withDrawModel.massage"
                  placeholder="请输入短信指令"
                  prefix-icon=""
                  style="width: 444px; margin-left: 12px"
              ></el-input>
              </span>
              </el-form-item>
            </el-row>
          </el-form>
        </div>

        <div class="footer-btn">
          <el-button type="primary" @click="back">上一步</el-button>
          <el-button type="primary" @click="save">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createOrUpdateEnterpriseCertification, findEnterpriseCertification} from "@/api/settlementManage";

export default {
  name: "authentication_manage",
  data() {
    return {
      nowBackground: true, //当前标题，true第一步，false第二步
      settlementId: "", //结算方id
      enterpriseCertificationId: "", //企业认证id
      model: {
        enterpriseName: "",
        enterprisePaperworkType: "",
        unifiedSocialCreditCode: "",
        legalPersonName: "",
        idCard: "",
        legalPersonTel: "",
        bankCode: "",
        accountOpeningBranch: "",
        bankAccountName: "",
        bankAccountNumber: "",
        contactName: "",
        contactTel: "",
        contactIdCard: "",
      },
      rules: {
        enterpriseName: [{required: true, message: "企业名称不能为空"}],
        enterprisePaperworkType: [{required: true, message: "请选择企业证件类型"}],
        unifiedSocialCreditCode: [{required: true, message: "企业证件号不能为空"}],
        legalPersonName: [{required: true, message: "法人姓名不能为空"}],
        idCard: [{required: true, message: "身份证号码不能为空"}],
        legalPersonTel: [{required: true, message: "法人手机号不能为空"}],
        bankCode: [{required: true, message: "开户行代码不能为空"}],
        accountOpeningBranch: [{required: true, message: "开户行支行名称不能为空"}],
        bankAccountName: [{required: true, message: "银行账户名称不能为空"}],
        bankAccountNumber: [{required: true, message: "银行账户号不能为空"}],
        contactName: [{required: true, message: "联系人姓名不能为空"}],
        contactTel: [{required: true, message: "联系人电话不能为空"}],
        contactIdCard: [{required: true, message: "联系人身份证号码不能为空"}],
      },
      withDrawModel: {
        bankAccountNumber: "",
        contactsMobile: "",
        amount: "",
        massage: "",
      },
      applyBtn: true, //申请小额打款按钮显示或隐藏
      rulesWithDraw: {}

    };
  },
  methods: {
    //返回
    goBack() {
      this.$router.back();
    },
    //点击下一步(暂时改为保存)
    next() {
      // this.nowBackground = false;
      this.$refs.model.validate((valid) => {
        if (valid) {
          const body = {
            accountOpeningBranch: this.model.accountOpeningBranch,
            bankAccountName: this.model.bankAccountName,
            bankAccountNumber: this.model.bankAccountNumber,
            bankCode: this.model.bankCode,
            contactIdCard: this.model.contactIdCard,
            contactName: this.model.contactName,
            contactTel: this.model.contactTel,
            enterpriseName: this.model.enterpriseName,
            enterprisePaperworkType: this.model.enterprisePaperworkType,
            id: this.enterpriseCertificationId,
            idCard: this.model.idCard,
            legalPersonName: this.model.legalPersonName,
            legalPersonTel: this.model.legalPersonTel,
            settlementId: this.settlementId,
            unifiedSocialCreditCode: this.model.unifiedSocialCreditCode
          }
          this.isLoading = true;
          createOrUpdateEnterpriseCertification(body).then(res => {
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "保存企业认证信息成功!",
                type: "success",
              });
            }
          });
        }
      });
    },
    //申请小额打款和短信指令验证按钮
    apply() {
      this.applyBtn = false;
    },
    //点击上一步
    back() {
      this.nowBackground = true;
    },
    //提交
    save() {
    },

    //编辑——查询当前已填写认证信息
    getEnterpriseCertification(id) {
      this.isLoading = true;
      findEnterpriseCertification(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          const returnObject = res.returnObject;
          this.enterpriseCertificationId = returnObject.id
          this.model = {
            enterpriseName: returnObject.enterpriseName,
            enterprisePaperworkType: returnObject.enterprisePaperworkType,
            unifiedSocialCreditCode: returnObject.unifiedSocialCreditCode,
            legalPersonName: returnObject.legalPersonName,
            idCard: returnObject.idCard,
            legalPersonTel: returnObject.legalPersonTel,
            bankCode: returnObject.bankCode,
            accountOpeningBranch: returnObject.accountOpeningBranch,
            bankAccountName: returnObject.bankAccountName,
            bankAccountNumber: returnObject.bankAccountNumber,
            contactName: returnObject.contactName,
            contactTel: returnObject.contactTel,
            contactIdCard: returnObject.contactIdCard
          }
        }
      })
    },
  },
  mounted() {
    const settlementId = this.$route.query.id;
    if (settlementId) {
      this.settlementId = settlementId;
      this.getEnterpriseCertification(settlementId);
    }
  }
};
</script>

<style lang="less" scoped>
.btn {
  float: right;
}

.title {
  width: 30%;
  margin: 20px auto !important;
}

.grid-content {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  background: #e5e9f2;
}

.footer-btn {
  text-align: center;
  margin: 30px 0;
}
</style>
